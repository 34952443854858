import debug from 'debug';

const api = async (endpoint) => {
  try {
    const response = await fetch(`/api/${endpoint}`);
    return response.json();
  } catch {
    const logger = debug('api');
    logger(`Error fetching data from ${endpoint}`);
    return {};
  }
};

export default api;
