import React, { useState, useEffect } from 'react';
import moment from 'moment';

import api from './api';
import './Client.scss';

const printableDate = (date, format) => {
  return moment(date, format).format('D MMM, YYYY');
};

const showStatus = (value) => {
  if (value === null) return 'Loading';
  return value ? 'OK' : 'Failure';
};

const showBotStatus = (dateString) => {
  if (!dateString) return 'Unknown';
  const messageDate = printableDate(dateString, 'YYYY-MM-DD');
  const now = printableDate(new Date());
  return messageDate === now ? 'OK' : `Outdated (${messageDate})`;
};

const showBuildInfo = (build) => {
  if (!build) return 'Loading';
  const date = printableDate(build.date, 'DD-MM-YYYY');
  return `${build.version} (${date})`;
};

const showCalendarStatus = ({updated, records}) => {
  if (!updated) return 'Loading';
  const date = printableDate(updated, 'MM/DD/YYYY');
  return `${records} records (${date})`;
}

const Client = () => {
  const [username, setUsername] = useState(null);
  const [lastMessage, updateMessage] = useState({date: null});
  const [build, updateBuild] = useState({});
  const [calendarStats, updateCalendarStats] = useState({});

  useEffect(() => {
    (async () => {
      updateBuild(await api('build'));
      setUsername((await api('user'))?.username);
      updateMessage(await api('db'));
      updateCalendarStats(await api('cal/status'));
    })();
  }, []);

  return (
    <>
      <h1>bzmn.xyz</h1>
      <ul className="statuses">
        <li>
          <b>Build</b>
          <span>{showBuildInfo(build)}</span>
        </li>
        <li>
          <b>API</b>
          <span>{showStatus(username)}</span>
        </li>
        <li>
          <b>Db</b>
          <span>{showStatus(lastMessage?.date)}</span>
        </li>
        <li>
          <b>Bot</b>
          <span>{showBotStatus(lastMessage?.date)}</span>
        </li>
        <li>
          <b>iCal</b>
          <span>{showCalendarStatus(calendarStats)}</span>
        </li>
      </ul>
    </>
  );
};

export default Client;
